import React from 'react';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import HomeImage from '../../assets/images/home.svg';
import '../HomeComponent/HomeComponent.css';
import { Link } from 'react-router-dom';
import Algo from '../../assets/images/dtec.jpg';
import googlecloud from '../../assets/images/gc.png';
import awscloud from '../../assets/images/aws.png';
import cf from '../../assets/images/cf.png';
import android from '../../assets/images/android.png';
import azurecloud from '../../assets/images/Microsoft_Azure_Logo.png';
import anon from '../../assets/images/insurance.png';
import fintech from '../../assets/images/fintech.png';
import networking from '../../assets/images/networking.png';
import codeLogo from '../../assets/images/code-solid.svg';
import mobileappimage from '../../assets/images/mobileapp.png'
import FooterComponent from '../FooterComponent/FooterComponent';

export default function HomeComponent() {
  return (
    <div className="homeDiv">
        <HeaderComponent/>
        <div className="mainContent container">
            <div className="row">
                <div className="col-12 col-md-6 contentHolder">
                    <h1 id="mainDivHeadingText">
                        We build custom software for startups and scaleups
                    </h1>
                    <Link to='/contactus' id="estimateButton">
                        Estimate your project
                        <span style={{marginLeft:'80px'}}>
                            {'>'}
                        </span>
                    </Link>
                </div>    
                <div className="col-12 col-md-6 contentHolder">
                    <img src={HomeImage} id="homeImage" alt="home" />
                </div>
            </div>    
            <hr style={{backgroundColor:'#4654A3'}} />  
            <div className="row">
                <div className="col-6 col-md-2">
                    <h6 style={{fontWeight:'bold', color:'#270949'}}>
                    Based in
                    </h6>
                    <img src={Algo} height={75} alt="algo"/>
                </div>                
            </div>
        </div>
        <div className="partnersContent container">
            <div className="row">
                <div className="col-md-6 co-12 partnersContentHolder">
                    <div className="partnersInnerDiv">
                        <h1 className='partnerMainHeading'>
                            Your trusted partner in building applications
                        </h1>   
                        <p className='partnerSubHeading'>
                            Build custom software that embraces technology with proven domain experts who can speak multiple languages.
                        </p> 
                        <Link to='/softwaredevelopment' className="readMoreButton">
                            Read more
                            <span style={{marginLeft:'30px'}}>
                                {'>'}
                            </span>
                        </Link>
                    </div>
                </div>    
                <div className="col-md-6 co-12 partnersContentHolder">
                    <div className="partnersInnerDivSecond">
                        <h1 className='partnerMainHeading'>
                            We understand your business requirements
                        </h1>   
                        <p className='partnerSubHeading'>
                            Our team not only builds but also acts as your research partner to review whitepapers and reports.
                        </p> 
                        <Link to='/softwaredevelopment' className="readMoreButton">
                            Read more
                            <span style={{marginLeft:'30px'}}>
                                {'>'}
                            </span>
                        </Link>
                    </div>
                </div> 
            </div>
            <div className="row mt-5 mt-md-0">
                <div className="col partnersImageHolder">
                    <img src={googlecloud} style={{height:'25px'}} alt="gc" />    
                </div>
                <div className="col partnersImageHolder">
                    <img src={azurecloud} style={{height:'25px'}} alt="ac" />    
                </div>
                <div className="col partnersImageHolder">
                    <img src={awscloud} style={{height:'25px'}} alt="aws" />    
                </div>
                <div className="col partnersImageHolder">
                    <img src={cf} style={{height:'25px'}} alt="cf" />    
                </div>
                <div className="col partnersImageHolder">
                    <img src={android} style={{height:'25px'}} alt="gc" />    
                </div>
            </div>
        </div>
        <div className="container testimonialDiv">
            <hr style={{backgroundColor:'#4654A3'}} />
            <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="container testimonialHolder">
                            <div className="row">
                                <div className="col-md-3 col-12 testimonialContentHolder">
                                    <div className="testimonialPerson">
                                        <img src={anon} alt="eth" className='testimonialImage' width={156} height={180} style={{borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}} />
                                        
                                    </div>
                                </div>
                                <div className="col-md-9 col-12 testimonialContentHolder">
                                    <h4 className='testimonialQuote'>
                                        ''Super cooperative and supportive''
                                    </h4>
                                    <h6 className='testminoialMainText'>
                                        I love working with the team at Riverline. My experience has been very good and I can only recommend everyone to give it a try. Absolutely professional service around the clock.
                                    </h6>

                                    <h5 className='testimonialName'>
                                        Director
                                    </h5>
                                    <h6 className='testimonialCompany'>
                                        An insurance product
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="container testimonialHolder">
                            <div className="row">
                                <div className="col-md-3 col-12 testimonialContentHolder">
                                    <div className="testimonialPerson">
                                        <img src={fintech} alt="eth" className='testimonialImage' width={156} height={180} style={{borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}} />
                                        
                                    </div>
                                </div>
                                <div className="col-md-9 col-12 testimonialContentHolder">
                                    <h4 className='testimonialQuote'>
                                        ''They understood exactly what we wanted to build''
                                    </h4>
                                    <h6 className='testminoialMainText'>
                                        Riverline has played a critical role in the development of our company, providing us with talented developers and support over the years. Outstanding technical knowledge, the highest level of attention to detail and a problem-solving mindset are what make them unique. We'd honestly recommend them.
                                    </h6>

                                    <h5 className='testimonialName'>
                                        Co-founder
                                    </h5>
                                    <h6 className='testimonialCompany'>
                                        A fintech company
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="container testimonialHolder">
                            <div className="row">
                                <div className="col-md-3 col-12 testimonialContentHolder">
                                    <div className="testimonialPerson">
                                        <img src={networking} alt="eth" className='testimonialImage' width={156} height={180} style={{borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}} />
                                        
                                    </div>
                                </div>
                                <div className="col-md-9 col-12 testimonialContentHolder">
                                    <h4 className='testimonialQuote'>
                                        ''Punctual and perfect''
                                    </h4>
                                    <h6 className='testminoialMainText'>
                                        Working with Riverline has been an absolute pleasure at every juncture in the projects we have collaborated on. The team is highly dedicated to their commitments and take great pains in working to deliver on time.
                                    </h6>

                                    <h5 className='testimonialName'>
                                        CEO
                                    </h5>
                                    <h6 className='testimonialCompany'>
                                        A networking startup
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <hr style={{backgroundColor:'#4654A3'}} />
        </div>
        <div className="servicesDiv container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="servicesCard">
                        <div className='codeCircle'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h1 className='serviceCardHeading'>
                            Software Development
                        </h1>
                        <p className='serviceCardText'>
                            We support the full product lifecycle, taking care of all your technical needs.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="servicesCard">
                        <div className='codeCircle'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h1 className='serviceCardHeading'>
                            Product Design
                        </h1>
                        <p className='serviceCardText'>
                            Let us shape your product idea and turn it into a development-ready project.
                        </p>
                    </div>
                </div>  
            </div> 
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="servicesCard">
                        <div className='codeCircle'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h1 className='serviceCardHeading'>
                            Smart Contracts
                        </h1>
                        <p className='serviceCardText'>
                            Build fully decentralized solutions on Algorand, Solana, or other blockchains.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="servicesCard">
                        <div className='codeCircle'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h1 className='serviceCardHeading'>
                            Security Audits
                        </h1>
                        <p className='serviceCardText'>
                            Our team covers audits on popular blockchains – Algorand, Solana, Flow, and more.
                        </p>
                    </div>
                </div>  
            </div>
            <div className="d-flex justify-content-center">
                    <Link to='/softwaredevelopment' className="readMoreServiceButton">
                        Read more
                    </Link>
            </div>
        </div>
        
        <div className='footerMargin'>
            <FooterComponent  />
        </div>
    </div>
  )
}

/*
<div className="container testimonialDiv mb-5">
            <hr style={{backgroundColor:'#4654A3'}} />
            <div id="carouselExampleDark2" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleDark2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleDark2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="container testimonialHolder">
                            <div className="row">
                                <div className="col-md-3 col-12 testimonialContentHolder">
                                    <img src={mobileappimage} alt="app" />
                                </div>
                                <div className="col-md-9 col-12 testimonialContentHolder">
                                    <h4 className='testimonialQuote'>
                                        Full Stack Support to Mobile Virtual Network Operator
                                    </h4>
                                    <h6 className='testminoialMainText'>
                                    The age of digital services, on-demand business’ and eCommerce are growing rapidly and moving all physical assets toward the Net. Now, the only thing which can make a lot of trouble for you is a lack of access to the Internet because of an account without any cash on in. Mobile Vikings, a mobile virtual network operator, noticed this market niche and as a result entered the Polish telecom market. Now, the company, together with Chain Labs, has been developing a
                                    </h6>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="container testimonialHolder">
                            <div className="row">
                                <div className="col-md-3 col-12 testimonialContentHolder">
                                    <img src={mobileappimage} alt="app" />
                                </div>
                                <div className="col-md-9 col-12 testimonialContentHolder">
                                    <h4 className='testimonialQuote'>
                                        Full Stack Support to Mobile Virtual Network Operator
                                    </h4>
                                    <h6 className='testminoialMainText'>
                                    The age of digital services, on-demand business’ and eCommerce are growing rapidly and moving all physical assets toward the Net. Now, the only thing which can make a lot of trouble for you is a lack of access to the Internet because of an account without any cash on in. Mobile Vikings, a mobile virtual network operator, noticed this market niche and as a result entered the Polish telecom market. Now, the company, together with Chain Labs, has been developing a
                                    </h6>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="container testimonialHolder">
                            <div className="row">
                                <div className="col-md-3 col-12 testimonialContentHolder">
                                    <img src={mobileappimage} alt="app" />
                                </div>
                                <div className="col-md-9 col-12 testimonialContentHolder">
                                    <h4 className='testimonialQuote'>
                                        Full Stack Support to Mobile Virtual Network Operator
                                    </h4>
                                    <h6 className='testminoialMainText'>
                                    The age of digital services, on-demand business’ and eCommerce are growing rapidly and moving all physical assets toward the Net. Now, the only thing which can make a lot of trouble for you is a lack of access to the Internet because of an account without any cash on in. Mobile Vikings, a mobile virtual network operator, noticed this market niche and as a result entered the Polish telecom market. Now, the company, together with Chain Labs, has been developing a
                                    </h6>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark2" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark2" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        */
