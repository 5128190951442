import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';

import HomeComponent from "./components/HomeComponent/HomeComponent";
import AboutUsComponent from './components/AboutUsComponent/AboutUsComponent';
import SoftwareServicesComponent from "./components/SoftwareServicesComponent/SoftwareServicesComponent";
import ContactUsComponent from "./components/ContactUsComponent/ContactUsComponent";
import ServiceComponent from "./components/SoftwareServicesComponent/ServiceComponent/ServiceComponent";


function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
            <Route path="*" element={<HomeComponent />} />
              <Route path="/" element={<HomeComponent />} />
              <Route path="/about" element={<AboutUsComponent />} />
              <Route path="/softwaredevelopment" element={<SoftwareServicesComponent />} />
              <Route path="/services" element={<ServiceComponent />} />
              <Route path="/contactus" element={<ContactUsComponent />} />                                              
            </Routes>
          </BrowserRouter>
    </div>
  );
}

export default App;

