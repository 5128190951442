import React from 'react'
import '../AboutUsComponent/AboutUsComponent.css';
import FooterComponent from '../FooterComponent/FooterComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import ie from '../../assets/images/ie.svg';
import transp from '../../assets/images/transp.svg';
import agil from '../../assets/images/agil.svg';
import { Link } from 'react-router-dom';
import leadingblocks from '../../assets/images/ideologo.png';
import leadingblocks2 from '../../assets/images/blackfishlogo.png';
import leadingblocks3 from '../../assets/images/yomikologo.png';
import logowall from '../../assets/images/logowall.png';
import innovation from '../../assets/images/innovation.png';

export default function AboutUsComponent() {
  return (
    <div className='aboutUsDiv'> 
    <HeaderComponent/>
        <div className="aboutUsMainDiv container">
          <h1 id="aboutUsMainHeading">About us</h1>  
          
          <div className="row" style={{color:'#4654A3'}}>
            <div className="col-md-6 col-12">
            <h3 id="aboutUsMainSubHeading">Supporting the innovators</h3>
            <hr style={{width:"100px",height:'3px',backgroundColor:'#4654A3',opacity:'1',marginTop:'30px',marginBottom:'30px'}} />
              <p>
                Riverline is a software house specializing in end-to-end software development across a wide array of industries–especially in machine learning, blockchain, and fintech. Our team has grown to over 50+ IT expert years that assist clients globally, and we continue to expand.
              </p>
              <p>
                Our purpose is to support the revolution that happens now within AI, finance, and web 3.0. We want to build products that shape the world around us with you – the innovators.
              </p>
            </div>
            <div className="col-1" />
            <div className="col-5">
              <img src={innovation} alt="leading" style={{marginTop: '10vh'}} />
            </div>
          </div>
          <hr style={{width:"100px",height:'3px',backgroundColor:'#4654A3',opacity:'1',marginTop:'30px',marginBottom:'30px'}} />
          <div className="row">
            <div className="col-md-6 col-12">
              <h5 style={{fontWeight:'bold',color:'#270949'}}>Vision</h5>
              <p style={{color:'#4654A3'}}>
                We aim to be the go-to partner for the best global tech-based companies by offering the best-in-class software engineering and strategic technology management.
              </p>
            </div>
            <div className="col-md-6 col-12">
              <h5 style={{fontWeight:'bold',color:'#270949'}}>Mission</h5>
              <p style={{color:'#4654A3'}}>
              We want to be where the tech revolution happens. We are driving it by expanding our position within the AI, blockchain and fintech industries. And we won’t settle for mediocre results.
              </p>
            </div>  
          </div>
          
        </div>
        <div className="standOutDiv container">
          <h4 style={{fontWeight:'bold',marginBottom:"20px",color:'#270949'}}>
            How do we stand out?
          </h4>
          <div className="row">
            <div className="col-md-4 col-12 pt-3">
              <img src={ie} alt="ie" />
              <h6 className='mt-4 mb-3' style={{fontWeight:'bold',color:'#270949'}}>
                Industry expertise
              </h6>
              <p style={{color:'#270949'}}>
              We speak your language! There are no secrets in tech and financial markets for us.
              </p>
            </div> 
            <div className="col-md-4 col-12 pt-3">
              <img src={transp} alt="transp" />
              <h6 className='mt-4 mb-3' style={{fontWeight:'bold',color:'#270949'}}>
                Transparency
              </h6>
              <p style={{color:'#270949'}}>
                Transparency You’ll always know how your development progresses. Feel invited to our stand-ups!
              </p>
            </div>
            <div className="col-md-4 col-12 pt-3">
              <img src={agil} alt="agil" />
              <h6 className='mt-4 mb-3' style={{fontWeight:'bold',color:'#270949'}}>
                Agility
              </h6>
              <p style={{color:'#270949'}}>
                We act fast on the emerging opportunities. Want to jump on a new trend? Count us in!
              </p>
            </div>
          </div>
        </div>
        <div className="aboutUsMainDiv container">
          <div className="row">
            <div className="teamDiv container col-lg-6 col-md-6">
              <h2 style={{fontWeight:'bold',color:'#270949'}}>Team</h2>
              <hr style={{width:"100px",height:'3px',backgroundColor:'#4654A3',opacity:'1',marginTop:'20px',marginBottom:'20px'}} />
              <p style={{color:'#4654A3'}}>
                Riverline is a team of over 50+ IT years of professional experience that keeps growing fast. We may come from various backgrounds, but that lets us look at your projects from many perspectives. The best products come from the best people, and at Riverline this is not only an empty claim.
              </p>
              <p style={{color:'#4654A3'}}>
                The industries we’re specialized in are the ones many of us are genuinely interested in – we’re active in ML and web 3 communities and carefully follow tech conferences and financial markets. Combined with the passion and dedication to thrive in what we do, it makes working with us a tremendous experience!
              </p>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-5 col-md-6">
              <img src={logowall} alt="leading" style={{marginTop: '10vh'}} />              
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <hr style={{backgroundColor:'#4654A3',opacity:'0.5'}}/>  
        </div>
        <div className="customersDiv container">
          <div className="row">
            <div className="col-md-4 col-12">
              <h4 style={{fontWeight:'bold',color:'#270949'}}>
                Our team is incomplete without you, our customers and partners
              </h4>
            </div>
            <div className="col-md-8 col-12 pt-4 pt-md-0 pb-5">
              <img src={leadingblocks} alt="leading" style={{marginRight:'30px'}} />
              <img src={leadingblocks3} alt="leading" style={{marginRight:'30px'}} />
              <img src={leadingblocks2} alt="leading" />              
            </div>  
            <hr style={{backgroundColor:'#4654A3',opacity:'0.5'}}/>
          </div>
        </div>
        <div className="questionDiv container">
            <h2 className='questionDivHeading'>Tell us about your project</h2>    
            <p style={{color:'#270949'}}>Get in touch and let’s build your project!</p>
            <Link className='questionDivButton' to='/contactus'>
                Contact us
            </Link>
        </div>
        <FooterComponent/>
    </div>
  )
}
