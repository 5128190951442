import React from 'react'
import Logo from '../../../src/assets/images/chainlabs.png';
import { Link } from "react-router-dom";
import '../HeaderComponent/HeaderComponent.css';

export default function HeaderComponent() {
  return (
    <nav className="container navbar navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
            <img src={Logo} height="38" width="200" alt="Logo"/>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.collapse">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbar">
                <ul className="navbar-nav ms-auto">
                    <Link className="nav-link" to="/about">
                        About us
                    </Link>
                    <Link className="nav-link" to="/softwaredevelopment">
                        Software development
                    </Link>                    
                    <Link className="nav-link" to="/contactus">
                        Contact us
                    </Link>
                    <hr />
                </ul>
        </div>
    </nav>
  )
}
