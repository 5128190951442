import React from 'react';
import '../FooterComponent/FooterComponent.css';
import Logo from '../../../src/assets/images/chainlabstransp.png';
import Twitter from '../../../src/assets/images/Twitter.svg';
import Telegram from '../../../src/assets/images/Telegram.svg';
import Discord from '../../../src/assets/images/Discord.svg';
import Contact from '../../../src/assets/images/envelope.svg';
import { Link } from 'react-router-dom';

export default function FooterComponent() {
  return (
    <div className='footerContainer'>
        <div className="container footerInnerDiv">
            <div className="footerFlexBox">
                <img src={Logo} style={{marginTop:'9px',marginRight:'4px'}} width={45} height={25} alt="logo" />
                <h2 className='footerName'>Riverline</h2>   
            </div>
             
            <div className="footerFlexBox">                
                <h6 className='footerName'>Office 124, Technohub, Dubai Silicon Oasis, UAE</h6>   
            </div>
            <hr style={{background:'white'}}/>
            <div className="footerFlexBox">
                <div>
                    
                    <Link to="/contactus" ><img src={Contact} width={30} height={30} alt="disc"/></Link>
                </div>
            </div>
        </div>
    </div>
  )
}

/*
<a href="http://www.google.com" target="_blank" style={{marginRight:"20px"}} rel="noopener noreferrer"><img src={Twitter} width={30} height={30} alt="twt"/></a>
<a href="http://www.google.com"  target="_blank" style={{marginRight:"20px"}} rel="noopener noreferrer"><img src={Telegram}  width={30} height={30} alt="tele"/></a>
<a href="http://www.google.com"  target="_blank" style={{marginRight:"20px"}} rel="noopener noreferrer"><img src={Discord} width={30} height={30} alt="disc"/></a>
*/