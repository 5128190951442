import React from 'react';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import '../SoftwareServicesComponent/SoftwareServicesComponent.css';
import codeLogo from '../../assets/images/code-solid.svg';
import { Link } from 'react-router-dom';
import JsLogo from '../../assets/images/js.svg';
import Vue from '../../assets/images/vue.svg';
import ReactLogo from '../../assets/images/react.svg';
import angular from '../../assets/images/angular.svg';
import swift from '../../assets/images/swift.svg';
import Team from '../../assets/images/team.jpg';
import kub from '../../assets/images/kub.svg';
import git from '../../assets/images/git.svg';
import pythonLogo from '../../assets/images/python.svg';
import nodelogo from '../../assets/images/node.svg';
import djangologo from '../../assets/images/django.svg';
import FooterComponent from '../FooterComponent/FooterComponent';

export default function SoftwareServicesComponent() {
  return (
    <div className='ssDiv'>
        <HeaderComponent/>
        <div className="softwareSerivcesDiv container">
            <h1 id="ssDivMainHeading">Software Development</h1>
            <p id="ssDivMainSubHeading">Let’s drive society forward. Together.</p>
            <p id="ssDivMainText">Riverline provides an end-to-end software development support to innovators and established businesses within the machine learning and fintech industries. With an expert insight into development and market knowledge, we can build something truly remarkable with you.
            </p>
            <div className="row mt-5">
                <div className="col-md-4 col-12">
                    <div className="ssCards">
                        <div className='codeCircle2'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h4 className='ssCardHeading'>Custom Software Development</h4>
                        <p className='ssCardText'>We support software development at any stage, especially using Python and JavaScript programming languages.</p>
                        <Link to="/services" className='ssReadMoreButton'>
                            Read more
                            <span style={{paddingLeft:'15px'}}>
                                {'>'}
                            </span>
                        </Link>
                    </div>
                </div> 
                <div className="col-md-4 col-12">
                    <div className="ssCards">
                        <div className='codeCircle2'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h4 className='ssCardHeading'>Smart Contracts Development</h4>
                        <p className='ssCardText'>We develop smart contracts and smart contract-based solutions on Algorand and Solana.</p>
                        <Link to="/services" className='ssReadMoreButton'>
                            Read more
                            <span style={{paddingLeft:'15px'}}>
                                {'>'}
                            </span>
                        </Link>
                    </div>
                </div> 
                <div className="col-md-4 col-12">
                    <div className="ssCards">
                        <div className='codeCircle2'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h4 className='ssCardHeading'>Smart Contract Audits</h4>
                        <p className='ssCardText'>Our expert team provides extensive auditing services to projects built on popular blockchains.</p>
                        <Link to="/services" className='ssReadMoreButton'>
                            Read more
                            <span style={{paddingLeft:'15px'}}>
                                {'>'}
                            </span>
                        </Link>
                    </div>
                </div>    
            </div>
            <div className="row mt-3 mb-5">
                <div className="col-md-4 col-12">
                    <div className="ssCards">
                        <div className='codeCircle2'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h4 className='ssCardHeading'>NFT Marketplace Development</h4>
                        <p className='ssCardText'>We are the go-to partner regarding building NFT Marketplaces for any use case.</p>
                        <Link to="/services" className='ssReadMoreButton'>
                            Read more
                            <span style={{paddingLeft:'15px'}}>
                                {'>'}
                            </span>
                        </Link>
                    </div>
                </div> 
                <div className="col-md-4 col-12">
                    <div className="ssCards">
                        <div className='codeCircle2'> 
                            <img src={codeLogo} alt="code" width={30} height={30}/>
                        </div>
                        <h4 className='ssCardHeading'>Digital Product Design</h4>
                        <p className='ssCardText'>Our internal product design team can help you transform your business idea into a development-ready platform design.</p>
                        <Link to="/services" className='ssReadMoreButton'>
                            Read more
                            <span style={{paddingLeft:'15px'}}>
                                {'>'}
                            </span>
                        </Link>
                    </div>
                </div>    
            </div>
        </div>
        <div className="technologyDiv container">
            <h2 id="techHeading">Technology we use</h2>
            <p id="techText">Chain Labs developers are using modern, secure and reliable technologies to turn your ideas into reality. Our experts have years of experience in technologies like Python, Vue, React & Kubernetes</p>
            <p className='techType'>Frontend and mobile</p>
            <div className="row">
                <div className="col-3 col-md-1 mt-4">
                    <img src={JsLogo} alt="tech" />    
                </div>
                <div className="col-3 col-md-1 mt-4">
                    <img src={Vue} alt="tech" />    
                </div>
                <div className="col-3 col-md-1 mt-4">
                    <img src={ReactLogo} alt="tech" />    
                </div>
                <div className="col-3 col-md-1 mt-4">
                    <img src={angular} alt="tech" />    
                </div>
                <div className="col-3 col-md-1 mt-4">
                    <img src={swift} alt="tech" />    
                </div>
            </div>
            <div className="row mt-5">
                <hr style={{backgroundColor:'#4654A3'}} />    
            </div>
            <p className='techType'>Backend</p>
            <div className="row">
                <div className="col-3 col-md-1 mt-4">
                    <img src={pythonLogo} alt="tech" />    
                </div>
                <div className="col-3 col-md-1 mt-4">
                    <img src={djangologo} alt="tech" />    
                </div>
                <div className="col-3 col-md-1 mt-4">
                    <img src={nodelogo} alt="tech" />    
                </div>
            </div>
            <p className='techType'>DevOps</p>
            <div className="row">
            <div className="col-3 col-md-1 mt-4">
                    <img src={kub} alt="tech" />    
                </div>
                <div className="col-3 col-md-1 mt-4">
                    <img src={git} alt="tech" />    
                </div>
            </div>
            <div className="row mt-5">
                <hr style={{backgroundColor:'#4654A3'}} />    
            </div>
        </div>
        <div className="businessOriented container">
            <h2 id="businessHeading">Business-oriented software development</h2>
            <p id="businessText">We’re focused on software development within the niches of fintech, blockchain, and crypto, marrying a solid understanding of the landscape you operate in with a proven track record of delivering high quality digital products.</p>
        </div>
        <div className="specialistDiv">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-12 specCol">
                        <img src={Team} id='TeamImage' alt="people"/>
                    </div>
                    <div className="col-md-4 col-12 specCol">
                        <h3 className='specialListHeading'>We are specialists in:</h3>
                        <ul className='spUl'>
                            <li className='spLi'>Blockchain</li>
                            <li className='spLi'>Cryptocurrencies</li>
                            <li className='spLi'>Fintech</li>
                            <li className='spLi'>Trading platforms</li>
                            <li className='spLi'>Cybersecurity</li>
                            <li className='spLi'>Telecommunications</li>
                            <li className='spLi'>Medtech</li>
                        </ul>
                    </div>    
                </div> 
            </div>
        </div>
        <div className="ourDevelopment">
            <div className="container">
                    <h3 id="ourDevelopmentHeading">
                        Our software development process
                    </h3>
                    <p id="ourDevelopmentText">Over the years, we have mastered the perfect process for seamless cooperation between our customers’ teams. For us, understanding the business needs of a partner is the only sound approach to successful software solutions.</p>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className='numberHolder'>
                                1
                            </div>
                            <h5 className='ourDevelopmentCardHeading'>Intro call</h5>
                            <p className='ourDevelopmentCardText'>
                                We’ll get to know each other and hear about your project to understand your needs. After the intro call, you’ll also know the general idea of delivery.
                            </p>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className='numberHolder'>
                                2
                            </div>
                            <h5 className='ourDevelopmentCardHeading'>Discovery workshop</h5>
                            <p className='ourDevelopmentCardText'>
                            The second meeting is to get the details–deeper project understanding, setting the scope, requirements, and expected outcome.
                            </p>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className='numberHolder'>
                                3
                            </div>
                            <h5 className='ourDevelopmentCardHeading'>Project kick-off</h5>
                            <p className='ourDevelopmentCardText'>
                                Once you decide to proceed, we’ll jump into planning the project roadmap with you.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className='numberHolder'>
                                4
                            </div>
                            <h5 className='ourDevelopmentCardHeading'>Development</h5>
                            <p className='ourDevelopmentCardText'>
                                We use Agile and Scrum, so you’re always up to date on the progress.
                            </p>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className='numberHolder'>
                                5
                            </div>
                            <h5 className='ourDevelopmentCardHeading'>Support and maintenance</h5>
                            <p className='ourDevelopmentCardText'>
                                After signing off the results, we’ll move to support and maintenance phase to make sure your system runs flawlessly.
                            </p>
                        </div>
                        
                    </div>
            </div>    
        </div>
        <div className="questionDiv container">
            <h2 className='questionDivHeading'>Have questions regarding developing your project?</h2>    
            <Link className='questionDivButton' to='/contactus'>
                Contact us
            </Link>
        </div>
        <FooterComponent/>
    </div>
  )
}
