import React from 'react'
import FooterComponent from '../../FooterComponent/FooterComponent'
import HeaderComponent from '../../HeaderComponent/HeaderComponent'

export default function ServiceComponent() {
  return (
    <div>
        <HeaderComponent/>
        <div className="content" style={{height:'80vh'}}>
            
        </div>
        <FooterComponent/>
    </div>
  )
}
