import React from 'react';
import '../ContactUsComponent/ContactUsComponent.css';
import FooterComponent from '../FooterComponent/FooterComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';

export default function ContactUsComponent() {

    return (
    <div className='contactUsDiv'>
        <HeaderComponent/>
        <div className="container contactUsInnerDiv">
            <div className="row">
                <div className="col-12 d-block d-md-none">
                    <div >
                        <p className='mt-4' style={{color:'#270949'}}>Contact us</p>
                        <hr style={{width:"100px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'10px',marginBottom:'30px'}} />
                        <h1 style={{fontWeight:'bold',paddingRight:'70px',color: '#270949'}}>Let’s see what we can create together</h1>
                    </div>
                </div>
                <div className="col-3 d-none d-md-block">
                    <div style={{paddingLeft:'10px'}}>
                        <h5 className='mt-5 pt-3' style={{color:'#270949'}}>Contact us</h5>
                        <hr style={{width:"100px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'30px',marginBottom:'30px'}} />
                        <h1 style={{fontWeight:'bold',paddingRight:'70px',color: '#270949'}}>Let’s see what we can create together</h1>
                    </div>
                </div>
                <div className="col-md-9 col-12">
                    <h3 className='mt-5 pt-3' style={{color:'#270949'}}>Services we provide</h3>
                    <div className="row">
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'10px',marginBottom:'30px'}} />
                        </div>
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'gray',marginTop:'10px',marginBottom:'30px'}} />
                        </div>
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'gray',marginTop:'10px',marginBottom:'30px'}} />
                        </div>    
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="optionsHolder">
                                <label htmlFor="1" style={{color:'#4654A3'}}>
                                    <span className='optionsLabel'>Python/C++ development</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="optionsHolder">
                                <label htmlFor="2" style={{color:'#4654A3'}}>
                                    <span className='optionsLabel'>Trading engines</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="optionsHolder">
                                <label htmlFor="3" style={{color:'#4654A3'}}>
                                    <span className='optionsLabel'>MVP Development</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="optionsHolder">
                                <label htmlFor="4" style={{color:'#4654A3'}}>
                                    <span className='optionsLabel'>Team extension</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="optionsHolder">
                                <label htmlFor="5" style={{color:'#4654A3'}}>
                                    <span className='optionsLabel'>(D)App development</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="optionsHolder">
                                <label htmlFor="6" style={{color:'#4654A3'}}>
                                    <span className='optionsLabel'>Security audits</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <h3 className='mt-5 pt-5' style={{color:'#270949'}}>Contact</h3>
                    <div className="row">
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'10px',marginBottom:'30px'}} />
                        </div>
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'10px',marginBottom:'30px'}} />
                        </div>
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'gray',marginTop:'10px',marginBottom:'30px'}} />
                        </div>    
                    </div>
                    <h5 style={{color:'#4654A3'}}>hi@riverline.xyz</h5>
                    <h3 className='mt-5 pt-5' style={{color:'#270949'}}>Corporate headquaters</h3>
                    <div className="row">
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'10px',marginBottom:'30px'}} />
                        </div>
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'10px',marginBottom:'30px'}} />
                        </div>
                        <div className="col-md-1 col-3">
                            <hr style={{width:"70px",height:'2px',backgroundColor:'#4654A3',opacity:'1',marginTop:'10px',marginBottom:'30px'}} />
                        </div>    
                    </div>
                    <h5 id="addressHolder" style={{color:'#4654A3'}}>Riverline Technologies FZE <br/> Office 124, Technohub, <br/> Dubai Silicon Oasis, <br/> United Arab Emirates</h5>
                </div>    
            </div>
        </div>
        <FooterComponent/>
    </div>
    )
}
